import { Component, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { OverlayFacade } from '@modules/cds/facades/overlay.facade';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cargamos-root',
  template: `
    <ng-progress [spinner]="false" [color]="'var(--cds-primary-color)'"></ng-progress>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  private readonly swUpdates: SwUpdate = inject(SwUpdate);
  private readonly overlayFacade: OverlayFacade = inject(OverlayFacade);
  private readonly translate: TranslateService = inject(TranslateService);

  constructor() {
    this.setupLanguage();
    this.swUpdates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(() => this.reloadApp());
  }

  private setupLanguage(): void {
    const defaultLanguage: string = 'es';
    this.translate.setDefaultLang(defaultLanguage);
    this.translate.use(defaultLanguage);
  }

  private reloadApp(): void {
    this.overlayFacade
      .showModalAlert({
        title: 'COMMON.NEW_VERSION_READY_TITLE',
        text: 'COMMON.NEW_VERSION_READY',
      })
      .pipe(filter(({ isConfirmed }) => isConfirmed))
      .subscribe(() => document.location.reload());
  }
}
