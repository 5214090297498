import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IdTokenResult } from '@angular/fire/auth';

import { environment as ENV } from '@environments/environment';
import { ErrorDetail } from '@cargamos/angular';
import { AuthService } from '@modules/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly authService: AuthService = inject(AuthService);
  private readonly translate: TranslateService = inject(TranslateService);
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.updateRequestHeaders(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isTokenExpired(error)) {
          return this.authService.refreshToken().pipe(
            tap((session: IdTokenResult | null) => {
              if (session) {
                this.authService.saveUserSession(session);
              }
            }),
            switchMap(() => next.handle(this.updateRequestHeaders(request)))
          );
        }

        const errors: Array<ErrorDetail> = error.error?.errors ?? this.getDefaultError();
        return throwError({
          errors,
          httpStatus: error?.status ?? 400,
        });
      })
    );
  }

  private isTokenExpired(error: HttpErrorResponse): boolean {
    return error.status === 401 && error.error.message === 'Jwt is expired';
  }

  private updateRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let headers: HttpHeaders = request.headers
      .append('Accept-Language', 'es')
      .append('origin-platform', 'ADMIN');
    const newParams: {
      [param: string]: string;
    } = {};

    const url: string = request.url;
    const authToken = this.authService.getAuthToken();

    if (!request.headers.has('Content-Type')) {
      headers = headers.append('Content-Type', 'application/json');
    }

    if (!request.headers.has('Authorization') && authToken) {
      headers = headers.append('Authorization', `Bearer ${authToken}`);
    }

    if (!url.includes('key=')) {
      newParams.key = ENV.hephaestus.apiKey;
    }

    return request.clone({
      headers,
      setParams: newParams,
    });
  }

  private getDefaultError(): Array<ErrorDetail> {
    return [
      {
        displayable_message: this.translate.instant('COMMON.GENERAL_ERROR'),
        code: 'UNKNOWN',
        details: this.translate.instant('COMMON.GENERAL_ERROR'),
        message: this.translate.instant('COMMON.GENERAL_ERROR'),
        parameter: 'UNKNOWN',
        location: 'UNKNOWN',
      },
    ];
  }
}
